/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Mono_5df1f2';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/5a7dbca54c2fc4dd-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Mono_5df1f2';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/d4e99c199dab3055-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Mono_5df1f2';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/9ae1ac821a4debaf-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Mono_5df1f2';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/ec1b1cbc512bc43e-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Mono_5df1f2';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/463cef7d4672ba68-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__IBM_Plex_Mono_Fallback_5df1f2';src: local("Arial");ascent-override: 76.16%;descent-override: 20.43%;line-gap-override: 0.00%;size-adjust: 134.59%
}.__className_5df1f2 {font-family: '__IBM_Plex_Mono_5df1f2', '__IBM_Plex_Mono_Fallback_5df1f2';font-weight: 300;font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__Inter_e2ca5f';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/a59d7bd9627a77dd-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_e2ca5f';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/b42529728e60f8ac-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_e2ca5f';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/75b5eda53d8a1864-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_e2ca5f';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/f18589f7100d2668-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_e2ca5f';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/3dd4ac5ee01e35f2-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_e2ca5f';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/ef30d321fb7b89ce-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_e2ca5f';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/7a17f3930f2fadbd-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_e2ca5f';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_e2ca5f {font-family: '__Inter_e2ca5f', '__Inter_Fallback_e2ca5f';font-weight: 300;font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__Old_Standard_TT_998701';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/5c80c53ebb0efcff-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Old_Standard_TT_998701';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/c64add873ff88910-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Old_Standard_TT_998701';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/3f770d1feadcbee6-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Old_Standard_TT_998701';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/5985c2cd44ef3a3c-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Old_Standard_TT_998701';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/ee318c4a1f659352-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Old_Standard_TT_Fallback_998701';src: local("Times New Roman");ascent-override: 70.52%;descent-override: 22.02%;line-gap-override: 21.84%;size-adjust: 108.06%
}.__className_998701 {font-family: '__Old_Standard_TT_998701', '__Old_Standard_TT_Fallback_998701';font-weight: 400;font-style: normal
}

